<script setup>
import { ref } from 'vue'
import { Head, router } from '@inertiajs/vue3'
import { Container, Modal } from '@codinglabsau/ui'
import Image from '@/Components/Files/Image.vue'
import { Button, Input, Textarea } from '@codinglabsau/gooey'
import { FormBuilder, useSchema } from '@codinglabsau/inertia-form-builder'
import { ship_image } from '@/helpers'
import PageHeader from '@/Components/PageHeader.vue'

const props = defineProps({
  ship: Object,
})

const deleteModal = ref(false)

const schema = useSchema({
  image: {
    component: Image,
    props: {
      currentImage: ship_image(props.ship.image),
      description: 'The image should be jpg, or png',
      supportedMimeTypes: ['image/jpeg', 'image/png'],
    },
  },
  name: {
    component: Input,
    value: props.ship.name,
  },
  description: {
    component: Textarea,
    value: props.ship.description,
  },
})

const deleteShip = () => {
  schema.form.delete(route('ships.delete', props.ship), {
    onError: () => {
      deleteModal.value = false
    },
  })
}

const submit = () => {
  schema.form
    .transform((data) => ({ ...data, _method: 'put' }))
    .post(route('ships.update', props.ship))
}

const cancel = () => {
  router.get(route('ships.show', props.ship))
}
</script>

<template>
  <Head title="Edit Ship" />

  <Container compact>
    <PageHeader title="Edit Ship">
      <template #actions>
        <Button variant="destructive" type="button" @click.prevent="deleteModal = true">
          Delete
        </Button>
      </template>
    </PageHeader>

    <div class="rounded-b-lg bg-white p-3 pt-4">
      <form @submit.prevent="submit">
        <FormBuilder :schema="schema">
          <template #actions="{ form }">
            <div class="space-x-2">
              <Button variant="secondary" @click="cancel"> Cancel</Button>
              <Button :loading="form.processing" type="submit"> Save</Button>
            </div>
          </template>
        </FormBuilder>
      </form>
    </div>
  </Container>

  <Modal :open="deleteModal" @close="deleteModal = false">
    <template #header>
      <span class="text-xl font-bold"> Delete Ship: {{ props.ship.name }} </span>
    </template>
    <div class="whitespace-pre-wrap">
      A ship can only be deleted if it has no voyages. <br />
      This action cannot be undone, are you sure you want to delete this ship?
    </div>
    <template #footer>
      <div class="flex w-full justify-end gap-3">
        <Button variant="secondary" type="button" @click.prevent="deleteModal = false">
          Cancel
        </Button>
        <Button
          variant="destructive"
          type="button"
          :loading="schema.form.processing"
          @click="deleteShip"
        >
          Delete
        </Button>
      </div>
    </template>
  </Modal>
</template>
