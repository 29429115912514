<script setup>
import { computed, ref, watch } from 'vue'
import { useDropZone, useFileDialog } from '@vueuse/core'
import Vapor from 'laravel-vapor'
import { fileSizeForHumans } from '@/helpers'

const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
  currentImage: {
    type: String,
    default: null,
  },
  description: {
    type: String,
    default: null,
  },
  supportedMimeTypes: {
    type: Array,
    required: true,
  },
})

const value = defineModel('modelValue', { type: Object, default: null })

const uploadedFile = ref(null)
const dropzone = ref()

const displayImage = computed(() => {
  if (uploadedFile.value && uploadedFile.value.preview) {
    return uploadedFile.value.preview
  }
  return props.currentImage
})

watch(
  uploadedFile,
  (val) => {
    if (val) {
      value.value = {
        uuid: val.uuid,
        key: val.key,
        type: val.type,
        name: val.name,
        description: val.description,
      }
    } else {
      value.value = null
    }
  },
  { deep: true }
)

function onFilesInput(file) {
  if (!file) {
    // skip if no file is provided
    return
  }

  uploadedFile.value = {
    raw: file,
    name: file.name,
    size_for_humans: fileSizeForHumans(file.size),
    progress: 0,
    type: file.type,
    preview: file.type.includes('image/') ? URL.createObjectURL(file) : null,
    description: '',
    error: null,
  }

  Vapor.store(uploadedFile.value.raw, {
    visibility: 'public-read',
    progress: (p) => {
      if (uploadedFile.value) {
        uploadedFile.value.progress = Math.round(p * 100)
      }
    },
  })
    .then((response) => {
      uploadedFile.value.uuid = response.uuid
      uploadedFile.value.key = response.key
      uploadedFile.value.type = response.headers['Content-Type']
    })
    .catch(() => {
      if (uploadedFile.value) {
        uploadedFile.value.error = 'Failed to upload file'
      }
    })
}

function onDrop(files) {
  onFilesInput(files?.[0])
}

useDropZone(dropzone, {
  onDrop,
  dataTypes: props.supportedMimeTypes,
})

const { open: openImagesDialog, onChange: onImagesChange } = useFileDialog({
  accept: 'image/*',
  multiple: false,
})
onImagesChange((files) => onFilesInput(Array.from(files)[0]))

function removeUploadedFile() {
  uploadedFile.value = null
  if (props.form?.hasErrors) {
    props.form.clearErrors()
  }
}
</script>

<template>
  <div class="flex flex-col gap-4">
    <div v-if="displayImage" class="flex flex-col gap-2">
      <img :src="displayImage" alt="Uploaded or current image" class="rounded max-w-full h-auto" />

      <div
        v-if="uploadedFile && uploadedFile.progress < 100 && !uploadedFile.error"
        class="h-1 bg-gray-200 w-full relative rounded overflow-hidden"
      >
        <div
          class="absolute inset-0 bg-blue-500 transition-all"
          :style="{ width: uploadedFile.progress + '%' }"
        ></div>
      </div>

      <div v-if="uploadedFile" class="flex items-center justify-between">
        <div class="text-sm">
          <strong>{{ uploadedFile.name }}</strong>
          <span class="ml-1">({{ uploadedFile.size_for_humans }})</span>
          <span v-if="uploadedFile.error" class="text-red-500 block">
            {{ uploadedFile.error }}
          </span>
        </div>
        <button @click="removeUploadedFile" class="text-red-600 hover:underline">Remove</button>
      </div>
    </div>

    <div
      ref="dropzone"
      class="border-2 border-dashed border-gray-300 rounded p-4 flex flex-col items-center justify-center cursor-pointer text-center"
      @click="openImagesDialog()"
    >
      <p class="text-gray-600">Drag and drop an image here or click to select</p>
    </div>

    <p v-if="description" class="text-gray-600 text-sm">
      {{ description }}
    </p>
  </div>
</template>
