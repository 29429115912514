<script setup>
import { Head, router } from '@inertiajs/vue3'
import { Container } from '@codinglabsau/ui'
import { Button, Input, Textarea } from '@codinglabsau/gooey'
import { FormBuilder, useSchema } from '@codinglabsau/inertia-form-builder'
import Image from '@/Components/Files/Image.vue'
import ShipPlaceholder from '@images/ship-placeholder.jpg'
import PageHeader from '@/Components/PageHeader.vue'

const schema = useSchema({
  image: {
    component: Image,
    currentImage: ShipPlaceholder,
    description: 'The image should be jpg or png',
    supportedMimeTypes: ['image/jpeg', 'image/png'],
  },
  name: Input,
  description: Textarea,
})

const submit = () => schema.form.post(route('ships.store'))
const cancel = () => router.get(route('ships.index'))
</script>

<template>
  <Head title="Add Ship" />

  <Container compact>
    <PageHeader title="Add Ship" />

    <div class="rounded-b-lg bg-white p-3 pt-4">
      <form @submit.prevent="submit">
        <FormBuilder :schema="schema">
          <template #actions="{ form }">
            <div class="space-x-2">
              <Button variant="secondary" @click="cancel"> Cancel</Button>
              <Button :loading="form.processing" type="submit"> Save</Button>
            </div>
          </template>
        </FormBuilder>
      </form>
    </div>
  </Container>
</template>
